var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ImgCell",
    _vm._b(
      {
        attrs: {
          image: _vm.agent.display_icon_small_url,
          label: _vm.agent.name
        }
      },
      "ImgCell",
      _vm.attributes,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }