var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "agent-composition" },
    _vm._l(_vm.computedAgents, function(agent, idx) {
      return _c(
        "AgentCell",
        _vm._b(
          {
            key: agent.id + idx,
            attrs: {
              highlight: _vm.isAgentHighlighted(agent),
              size: _vm.size,
              variant: "icon"
            }
          },
          "AgentCell",
          agent,
          false
        )
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }